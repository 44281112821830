import { SceneItem, Source } from "../obs";
import { Node } from "./node";
import { type ISlotContext } from "./slots";
import { downloadAndInstallFont } from "@/lib/fonts";

interface ISchema {
  settings: {
    text: string;
    color: number;
    custom_font: string;
    font: { size: number; face: string; flags: number };
    gradient: boolean;
    gradient_color: number;
    outline: true;
  };
}

export class TextNode extends Node<ISchema, ISlotContext> {
  schemaVersion = 1;

  async load(context: ISlotContext) {
    if (!this.data) return;

    if (this.data.settings.custom_font) {
      await downloadAndInstallFont(this.data.settings.custom_font);
    }

    const source = await Source.create("text_gdiplus", context.name);
    await source.update({
      text: this.data.settings.text,
      color: this.data.settings.color,
      gradient: this.data.settings.gradient,
      gradient_color: this.data.settings.gradient_color,
      outline: this.data.settings.outline,
      font: this.data.settings.font,
    });

    context.item = SceneItem.find(context.scene.name, context.name);
  }
}
