import { SceneItem, Source, type TSourceSettings } from "../obs";
import { Node } from "./node";
import { type ISlotContext } from "./slots";
import Bindings from "~/lib/bindings";

interface ISchema {
  placeholderFile: string;
  width: number; // Exported base resolution width
  height: number; // Exported base resolution height
}

export class GameCaptureNode extends Node<ISchema, ISlotContext> {
  schemaVersion = 2;

  async load(context: ISlotContext) {
    // A custom placeholder is not always provided
    if (this.data?.placeholderFile) {
      // For now, just treat this as an image, since OBS game capture doesn't
      // support placeholder images.
      const filePath = `${context.assetsPath}\\${this.data.placeholderFile}`;
      const settings: TSourceSettings = { file: filePath };
      const name = `${context.name} Background`;
      const source = await Source.create("image_source", name);
      await source.update(settings);

      context.item = SceneItem.find(context.scene.name, name);
    }

    await Source.create("game_capture", context.name);
    const item = SceneItem.find(context.scene.name, context.name);
    const canvas = await Bindings.obs.canvas_get_dimensions();
    await item.setPos(context.x * canvas.width, context.y * canvas.height);
  }

  override migrate(version: number) {
    if (version === 1) {
      // Assume 1080p as that will almost always be right
      this.data!.width = 1920;
      this.data!.height = 1080;
    }
  }
}
