import Bindings from "./bindings";

export interface IFontStyle {
  name: string;
  file: string;
}

export interface IFontFamily {
  name: string;
  styles: IFontStyle[];
}

export interface IFontManifest {
  families: IFontFamily[];
}

const FONT_LIB_BASE = "https://slobs-cdn.streamlabs.com/fonts/";

let _manifest: IFontManifest;

export async function getManifest() {
  if (!_manifest) {
    _manifest = await (await fetch(`${FONT_LIB_BASE}manifest.json`)).json();
  }

  return _manifest;
}

export async function findFamily(
  family: string,
): Promise<IFontFamily | undefined> {
  const manifest = await getManifest();

  return manifest.families.find((fam) => fam.name === family);
}

export async function findStyle(
  family: string,
  style: string,
): Promise<IFontStyle | undefined> {
  const fam = await findFamily(family);

  if (!fam) return undefined;

  return fam.styles.find((sty) => sty.name === style);
}

export async function downloadFont(file: string) {
  const result = await Bindings.fs.downloadFile(
    `${FONT_LIB_BASE}${file}`,
    file,
  );

  return result.path.map((c) => String.fromCharCode(c)).join("");
}

export async function getFamilyFromFile(filename: string) {
  const manifest = await getManifest();

  return manifest.families.find((fam) => {
    return !!fam.styles.find((style) => style.file === filename);
  });
}

// Avoid redownloading fonts
const downloadedFonts = new Set<string>();

export async function downloadAndInstallFont(filename: string) {
  if (downloadedFonts.has(filename)) return;

  const fontPath = await downloadFont(filename);

  await Bindings.fs.installFont(fontPath.replaceAll("\\", "/"));

  downloadedFonts.add(filename);
}
