import { SceneItem } from "../obs";
import { Node } from "./node";
import { type ISlotContext } from "./slots";

// Do not alter the order of this enum, it is coupled to the values stored in overlay files
export enum WidgetType {
  AlertBox = 0,
  DonationGoal = 1,
  FollowerGoal = 2,
  SubscriberGoal = 3,
  BitGoal = 4,
  DonationTicker = 5,
  ChatBox = 6,
  EventList = 7,
  TipJar = 8,
  ViewerCount = 9,
  StreamBoss = 10,
  Credits = 11,
  SpinWheel = 12,
  SponsorBanner = 13,
  MediaShare = 14,
  SubGoal = 15,
  StarsGoal = 16,
  SupporterGoal = 17,
  CharityGoal = 18,
  Poll = 19,
  EmoteWall = 20,
  ChatHighlight = 21,
  SuperchatGoal = 22,
  GameWidget = 23,
}

interface ISchema {
  settings: any;
  type: WidgetType;
}

export class WidgetNode extends Node<ISchema, ISlotContext> {
  schemaVersion = 1;

  async load(context: ISlotContext) {
    const widgets = useWidgetsStore();

    // Map overlay enum format to widgets store ids
    const widgetId = {
      [WidgetType.AlertBox]: 'alert-box',
      [WidgetType.DonationGoal]: 'tip-goal',
      [WidgetType.FollowerGoal]: 'follower-goal',
      [WidgetType.SubscriberGoal]: 'follower-goal', // YouTube
      [WidgetType.BitGoal]: 'bit-goal',
      [WidgetType.DonationTicker]: 'tip-ticker',
      [WidgetType.ChatBox]: 'chat-box',
      [WidgetType.EventList]: 'event-list',
      [WidgetType.TipJar]: 'the-jar',
      [WidgetType.ViewerCount]: 'viewer-count',
      [WidgetType.StreamBoss]: 'stream-boss',
      [WidgetType.Credits]: 'end-credits',
      [WidgetType.SpinWheel]: 'spin-wheel',
      [WidgetType.SponsorBanner]: 'sponsor-banner',
      [WidgetType.MediaShare]: 'media-share',
      [WidgetType.SubGoal]: 'sub-goal',
      [WidgetType.StarsGoal]: 'stars-goal',
      [WidgetType.SupporterGoal]: 'supporter-goal',
      [WidgetType.CharityGoal]: 'streamlabs-charity-donation-goal',
      [WidgetType.Poll]: 'poll',
      [WidgetType.EmoteWall]: 'emote-wall',
      [WidgetType.ChatHighlight]: 'chat-highlight',
      [WidgetType.SuperchatGoal]: 'super-chat-goal',
      [WidgetType.GameWidget]: 'game-widget',
    }[this.data?.type ?? WidgetType.AlertBox];

    const widget = widgets.all.find((w: Widget) => w.id === widgetId);
    if (widget) {
      const widgetCreation = { ...widget, settings: { ...this.data?.settings ?? {}}, name: context.name };

      await widgets.addWidgetToCurrentScene(widgetCreation);
      context.item = SceneItem.find(context.scene.name, context.name);
    }
  }
}
