import { SceneItem, Source, type TSourceSettings } from "../obs";
import { Node } from "./node";
import { type ISlotContext } from "./slots";

interface ISchema {
  settings: object;
  filename: string;
}

export class VideoNode extends Node<ISchema, ISlotContext> {
  schemaVersion = 1;

  async load(context: ISlotContext) {
    const filePath = `${context.assetsPath}\\${this.data?.filename}`;
    const settings: TSourceSettings = { ...this.data?.settings };
    settings["local_file"] = filePath;

    const source = await Source.create("ffmpeg_source", context.name);
    await source.update(settings);

    context.item = SceneItem.find(context.scene.name, context.name);
  }
}
