import { SceneItem, Source, type TSourceSettings } from "../obs";
import { Node } from "./node";
import { type ISlotContext } from "./slots";

interface ISchema {
  folder: string;
}

export class IconLibraryNode extends Node<ISchema, ISlotContext> {
  schemaVersion = 1;

  async load(context: ISlotContext) {
    const iconFolder = `${context.assetsPath}\\${this.data!.folder}`;
    const iconContents = context.contents.filter(
      (p) => p.path.indexOf(iconFolder) > -1,
    );

    // TODO: Working past a bug in plugin - remove after Steven fixes
    const icon = iconContents[0].path.replaceAll("/", "\\");

    const settings: TSourceSettings = { file: icon };
    const source = await Source.create("image_source", context.name);
    await source.update(settings);

    context.item = SceneItem.find(context.scene.name, context.name);
  }
}
