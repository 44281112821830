import { SceneItem, Source } from "../obs";
import { Node } from "./node";
import { type ISlotContext } from "./slots";

interface ISceneNodeSchema {
  sceneId: string;
  width: number; // Exported base resolution width
  height: number; // Exported base resolution height
}

export class SceneSourceNode extends Node<ISceneNodeSchema, ISlotContext> {
  schemaVersion = 2;

  async load(context: ISlotContext) {
    const sceneName = context.sceneIdMap[this.data!.sceneId];
    context.scene.addSource(new Source("scene", sceneName));
    context.item = SceneItem.find(context.scene.name, sceneName);
  }

  override migrate(version: number) {
    if (version === 1) {
      // Assume 1080p as that will almost always be right
      this.data!.width = 1920;
      this.data!.height = 1080;
    }
  }
}
