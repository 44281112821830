import { type ICrop, SceneItem, Source } from "../obs";
import { Node } from "./node";
import { type ISlotContext } from "./slots";
import Bindings from "~/lib/bindings";

interface ISchema {
  width: number;
  height: number;
}

// CONFIGURATION:
// Because we don't have this system fully implemented yet,
// we are hardcoding some parameters
const WEBCAM_DEVICE_ID =
  "Brio 500:\\\\?\\usb#22vid_046d&pid_0943&mi_00#226&2f4fffd8&0&0000#22{65e8773d-8f56-11d0-a3b9-00a0c9223196}\\global";
const WEBCAM_WIDTH = 1280;
const WEBCAM_HEIGHT = 720;

export function setWebcamDeviceId(id: string) {
  // WEBCAM_DEVICE_ID = id;
}

export function setWebcamDeviceResolution(w: number, h: number) {
  // WEBCAM_WIDTH = w;
  // WEBCAM_HEIGHT = h;
}

export class WebcamNode extends Node<ISchema, ISlotContext> {
  schemaVersion = 1;

  async load(context: ISlotContext) {
    let source = (await Source.findByType("dshow_input"))[0];

    if (source) {
      // Add to the current scene
      context.scene.addSource(source);
    } else {
      source = await Source.create("dshow_input", context.originalName);

      const connectedWebcams = (await source.getProperties())[0].video_device_id
        .items;

      if (connectedWebcams.length) {
        await source.update({
          video_device_id: connectedWebcams[0].value_string,
          res_type: 1,
          resolution: `${WEBCAM_WIDTH}x${WEBCAM_HEIGHT}`,
        });
      }
    }

    const canvas = await Bindings.obs.canvas_get_dimensions();
    const targetWidth = this.data!.width * canvas.width;
    const targetHeight = this.data!.height * canvas.height;
    const cameraWidth = WEBCAM_WIDTH;
    const cameraHeight = WEBCAM_HEIGHT;
    const targetAspect = targetWidth / targetHeight;
    const currentAspect = cameraWidth / cameraHeight;

    const crop: ICrop = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };
    let scale: number;

    if (currentAspect >= targetAspect) {
      // Scale the height to match, and crop the remaining width
      scale = targetHeight / cameraHeight;
      const deltaWidth = (cameraWidth * scale - targetWidth) / scale;

      crop.left = Math.floor(deltaWidth / 2);
      crop.right = Math.floor(deltaWidth / 2);
    } else {
      // Scale the width to match, and crop the remaining height
      scale = targetWidth / cameraWidth;
      const deltaHeight = (cameraHeight * scale - targetHeight) / scale;

      crop.top = Math.floor(deltaHeight / 2);
      crop.bottom = Math.floor(deltaHeight / 2);
    }

    const item = SceneItem.find(context.scene.name, context.originalName);
    item.setScale(scale, scale);
    item.setCrop(crop);

    context.item = item;
  }
}
