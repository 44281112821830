import { Scene } from "../obs";
import { type OverlayContents } from "../install-theme";
import { ArrayNode } from "./array-node";
import { SlotsNode } from "./slots";
import { uuidv4 } from "@/lib/uuid";
import { fetchStreamLabelsFiles } from "~/lib/streamlabs-api";
import Bindings from "~/lib/bindings";

interface ISchema {
  name: string;
  sceneId: string;
  slots: SlotsNode;
}

interface IContext {
  assetsPath: string;
  contents: OverlayContents;
}

export class ScenesNode extends ArrayNode<ISchema, IContext> {
  schemaVersion = 2;

  sceneIdMap: { [sceneId: string]: string } = {};

  streamlabels: { [labelFile: string]: string } = {};

  override async beforeLoad(context: IContext): Promise<void> {
    const { data: streamLabelsFiles } = await fetchStreamLabelsFiles();
    this.streamlabels = streamLabelsFiles.value.data;
  }

  async loadItem(
    obj: ISchema,
    context: IContext,
  ): Promise<() => Promise<void>> {
    // const scene = this.scenesService.createScene(obj.name, {
    //   sceneId: obj.sceneId,
    // });

    // // Load items into the scene after all scenes have been created
    // // to handle scene-in-scene situations
    // return async () => {
    //   await obj.slots.load({
    //     scene,
    //     assetsPath: context.assetsPath,
    //     savedAssets: context.savedAssets,
    //   });

    //   // append children to folders
    //   const foldersSchemas = (obj.slots.data.items as TSlotSchema[])
    //     .filter(item => item.sceneNodeType === 'folder')
    //     .reverse();

    //   const folders = scene.getFolders();
    //   folders.forEach((folder, ind) => {
    //     const childrenIds = (foldersSchemas[ind] as IFolderSchema).childrenIds;
    //     scene.getSelection(childrenIds).moveTo(scene.id, folder.id);
    //   });
    // };

    const scene = await Scene.create(obj.name);
    this.sceneIdMap[obj.sceneId] = obj.name;

    // Load items into the scene after all scenes have been created
    // to handle scene-in-scene situations
    return async () => {
      await scene.makeActive();
      await obj.slots.load({
        scene,
        assetsPath: context.assetsPath,
        sceneIdMap: this.sceneIdMap,
        streamlabels: this.streamlabels,
        contents: context.contents,
      });
    };
  }

  override async afterLoad(): Promise<void> {
    if (this.data?.items[0].name) {
      await Bindings.obs.set_current_scene(this.data?.items[0].name);
    }
  }

  override migrate(version: number) {
    if (version === 1) {
      // version 1 doesn't have sceneId, so generate a random id
      this.data!.items = this.data!.items.map((item) => {
        if (item.sceneId) return item;
        return { ...item, sceneId: uuidv4() };
      });
    }
  }
}
