import { Source } from "../obs";
import { Node } from "./node";
import { type ISlotContext } from "./slots";
import { TextNode } from "./text";
import { STREAMLABELS_SETTING_KEY } from "~/stores/obs.store";

interface ISchema {
  labelType: string;
  textSource: TextNode;
}

export class StreamlabelNode extends Node<ISchema, ISlotContext> {
  schemaVersion = 1;

  async load(context: ISlotContext) {
    await this.data!.textSource.load(context);
    await Source.update(context.name, {
      [STREAMLABELS_SETTING_KEY]: this.data?.labelType!,
    });
  }
}
