import {
  fetchOverlay,
  fetchWidgetTheme,
  fetchInstallWidgetTheme,
} from "~/lib/streamlabs-api";

import { until } from "@vueuse/core";
import Bindings from "~/lib/bindings";
import { getConfigFromPaths } from "~/lib/themes/install-theme";
import { createUniqueSceneCollection } from "~/lib/themes/obs";
import { sleep } from "~/lib/util";

type SortValues = "total_installs" | "created_at" | "recent_installs";
export type TypeValues = "all" | "overlay" | "widget"; // | "page";

export const useLibraryStore = defineStore("library", () => {
  const dialogs = useDialogsStore();
  const api = useApiStore();
  const auth = useAuthStore();

  const { public: $env } = useRuntimeConfig();
  const { queryParamToComputed } = useQueryParamToComputed();

  const isInstalling = ref(false);
  const installProgress = ref(0);
  const installStepLabel = ref("Warming up");

  const setInstallProgress = (progress: number, label: string) => {
    installProgress.value = progress;
    installStepLabel.value = label;
  };

  const installOverlayFileUrl = async (
    url: string,
    sceneCollectionName: string,
  ) => {
    console.log("installOverlayFileUrl");
    setInstallProgress(40, "Downloading overlay config");

    const paths = await Bindings.fs.downloadZip(url);

    console.log({ paths });

    setInstallProgress(50, "Parsing config paths");

    const { config, configPath, contents } = await getConfigFromPaths(paths);

    console.log({ configPath, config });

    setInstallProgress(60, "Creating scene collection");

    // Create a fresh scene collection to load into
    await createUniqueSceneCollection(sceneCollectionName);

    setInstallProgress(80, "Loading overlay sources");

    await config.load({ assetsPath: configPath.slice(0, -12), contents });

    setInstallProgress(100, "All done!");

    await sleep(1000);

    isInstalling.value = false;

    dialogs.showDialog("RESTART_OBS");

    await Bindings.obs.toggle_hide_self(false);
  };

  const carouselOverlays = useDeferredAsyncData(
    "carousel-overlays",
    async () => {
      const { data } = await api.get<CarouselItem[]>(
        `${$env.MARKETPLACE_ORIGIN}/api/v2/dashboard/carousel?type=overlays`,
      );

      return data;
    },
  );

  const dashboardOverlays = useDeferredAsyncData(
    "dashboard-overlays",
    async () => {
      const { data } = await api.get<ResponseDashboard>(
        `${$env.MARKETPLACE_ORIGIN}/api/v2/dashboard?type=overlays`,
      );

      return data;
    },
  );

  const trendingCollections = useDeferredAsyncData(
    "trending-collections",
    async () => {
      const { data } = await api.get<{
        current_page: number;
        data: LibraryCollection[];
      }>(
        `${$env.MARKETPLACE_ORIGIN}/api/v2/collections?location=advanced_search`,
      );

      return data.data;
    },
  );

  const collections = useDeferredAsyncData("collections", async () => {
    const { data } = await api.get<{
      current_page: number;
      data: LibraryCollection[];
    }>(`${$env.MARKETPLACE_ORIGIN}/api/v2/collections`);

    return data.data;
  });

  const fetchItem = async (itemId: number | string) => {
    return await api.get<TsThemesItemWidget | TsThemesItemOverlay>(
      `${$env.MARKETPLACE_ORIGIN}/api/v2/themes/${itemId}`,
    );
  };

  const fetchBundledItems = async (itemId: number | string) => {
    return await api.get<
      Omit<TsThemesItemWidget | TsThemesItemOverlay, "creator">[]
    >(`${$env.MARKETPLACE_ORIGIN}/api/v2/themes/${itemId}/bundle`);
  };

  const installOverlay = async (overlay: TsThemesItemOverlay) => {
    if (
      !overlay.is_free &&
      !auth.ensureUltra("overlay_install", {
        refl: "obsplugin-theme-library",
        source: "library-overlays",
        ref_item: overlay.object.id,
        aff_id: overlay.creator.aff_id,
      })
    ) {
      return;
    }

    setInstallProgress(0, "Initializing");

    isInstalling.value = true;

    setInstallProgress(20, "Fetching overlay info");

    const {
      data: {
        package: {
          overlays: { default: overlayFileUrl },
        },
      },
    } = await api.post<{
      type: "overlay";
      package: {
        overlays: {
          default: string;
          [locale: string]: string;
        };
      };
    }>(`${$env.MARKETPLACE_ORIGIN}/api/v2/themes/${overlay.id}/install`);

    try {
      await Bindings.obs.toggle_hide_self(true);
    } catch (err) {
      console.error(err);
    }

    console.log({ overlayFileUrl });

    await installOverlayFileUrl(overlayFileUrl, overlay.name);
  };

  const installWidgetTheme = async (
    widgetTheme: TsThemesItemWidget,
    widgets?: TsWidgetType[],
  ) => {
    if (!widgetTheme.is_free) {
      if (
        !auth.ensureUltra("widget_theme_install", {
          refl: "obsplugin-widget-library",
          source: "library-widget-themes",
          ref_item: widgetTheme.object.id,
          aff_id: widgetTheme.creator.aff_id,
        })
      ) {
        return;
      }
    }

    const { data: legacyWidgetTheme } = await fetchWidgetTheme(
      widgetTheme.object.id,
    );

    console.log({ legacyWidgetTheme: legacyWidgetTheme.value });

    if (!legacyWidgetTheme.value) {
      return;
    }

    await fetchInstallWidgetTheme(
      legacyWidgetTheme.value,
      widgets && widgets.length ? (widgets as WidgetThemeType[]) : undefined,
    );
  };

  const page = ref(1);
  const hasMoreItems = ref(true);
  const perPage = ref(30);

  const items = ref<LibraryItem[]>([]);

  const typeOptions: { [key in TypeValues]: string } = {
    all: "All Types",
    overlay: "Overlays",
    widget: "Widget Themes",
  };

  const type = queryParamToComputed<TypeValues>("type", {
    set: (v) => (v === "all" ? undefined : v),
    get: (v) => v || "all",
  });

  const sortOptions: { [key in SortValues]: string } = {
    total_installs: "Most Installed",
    created_at: "Newest",
    recent_installs: "Trending",
  };

  const sort = queryParamToComputed<SortValues | "">("sort");

  const order = ref<"asc" | "desc" | undefined>("desc");
  const price = ref<"free" | "ultra" | undefined>();

  const selectedColors = queryParamToComputed<string[]>("colors", {
    get: (v) => (v ? (Array.isArray(v) ? v : [v]) : []),
  });

  const keywords = computed(() => {
    return selectedColors.value;
  });

  const q = queryParamToComputed("q");

  const fetchPage = async (page: number, libraryType: LibraryType) => {
    const url = new URL(`${$env.MARKETPLACE_ORIGIN}/api/v2/themes`);

    // if (collection.value) {
    //   url.searchParams.set("collection", collection.value);
    // }

    if (libraryType) {
      url.searchParams.set("type", libraryType);
    } else if (type.value && type.value !== "all") {
      url.searchParams.set("type", type.value);
    }

    if (sort.value) {
      url.searchParams.set("sort", sort.value);
    }

    if (order.value) {
      url.searchParams.set("order", order.value);
    }

    if (perPage.value) {
      url.searchParams.set("per_page", perPage.value.toString());
    }

    if (price.value) {
      url.searchParams.set("price", price.value);
    }

    if (q.value) {
      url.searchParams.set("search", q.value);
    }

    if (keywords.value.length) {
      for (const i in keywords.value) {
        url.searchParams.set(`keywords[${i}]`, keywords.value[i]);
      }
    }

    url.searchParams.set("page", page.toString());

    //   await sleep(30000);
    return await api.get<{
      data: LibraryItem[];
      meta: {
        current_page: number;
        from: number;
        last_page: number;
        links: {
          url: string | null;
          label: string;
          active: boolean;
        }[];
        path: string;
        per_page: number;
        to: number;
        total: number;
      };
    }>(url.toString());
  };

  const placeholderAndFetchNextPage = async ({
    type,
  }: {
    type: LibraryType;
  }) => {
    if (!hasMoreItems.value) {
      return;
    }

    hasMoreItems.value = false;

    items.value.push(...new Array(perPage.value).fill(undefined));

    const result = await fetchPage(page.value++, type);

    const fetchedItems = result.data?.data;

    items.value.splice(-perPage.value);

    if (fetchedItems) {
      items.value.push(...fetchedItems);
    } else {
      hasMoreItems.value = false;
      await until(hasMoreItems).toBe(true);
    }

    hasMoreItems.value =
      !!result.data?.meta.last_page && page.value < result.data?.meta.last_page;

    return result;
  };

  return {
    carouselOverlays,
    dashboardOverlays,
    trendingCollections,
    collections,

    fetchItem,
    fetchBundledItems,

    installOverlay,
    installWidgetTheme,

    isInstalling,
    installProgress,
    installStepLabel,

    selectedColors,
    keywords,
    q,
    sort,
    sortOptions,
    type,
    typeOptions,
    page,
    hasMoreItems,
    perPage,
    items,

    placeholderAndFetchNextPage,
  };
});
