import { Source, Transition } from '../obs';
import { Node } from './node';

export enum ETransitionType {
  Cut = 'cut_transition',
  Fade = 'fade_transition',
  Swipe = 'swipe_transition',
  Slide = 'slide_transition',
  FadeToColor = 'fade_to_color_transition',
  LumaWipe = 'wipe_transition',
  Stinger = 'obs_stinger_transition',
  Motion = 'motion_transition',
}

interface ISchema {
  type: ETransitionType;
  duration: number;
  settings?: { [setting: string]: unknown };
}

interface IContext {
  assetsPath: string;
}

// TODO: Fix this node
export class TransitionNode extends Node<ISchema, IContext> {
  schemaVersion = 1;

  async load(context: IContext) {
    const transition = await Transition.create(this.data!.type, 'Overlay Transition');

    if (this.data!.type === 'obs_stinger_transition') {
      this.data!.settings!.path = `${context.assetsPath}\\${this.data!.settings!.path}`;
    }

    await transition.update(this.data!.settings ?? {});

    await transition.makeActive();
  }
}
