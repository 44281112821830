import { Node } from './node';

interface ISceneNodeMapSchema {
  sceneNodeMaps: { [sceneId: string]: { [sceneItemId: string]: string } };
}

export class NodeMapNode extends Node<ISceneNodeMapSchema, {}> {
  schemaVersion = 1;

  async load() {
    // if (this.data?.sceneNodeMaps) {
    //   this.sceneCollectionsService.initNodeMaps(this.data.sceneNodeMaps);
    // }
  }
}
