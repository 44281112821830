import { Scene, SceneItem, getUniqueSourceName } from "../obs";
import { type OverlayContents } from "../install-theme";
import { ArrayNode } from "./array-node";
import { ImageNode } from "./image";
import { TextNode } from "./text";
import { WebcamNode } from "./webcam";
import { VideoNode } from "./video";
import { StreamlabelNode } from "./streamlabel";
import { IconLibraryNode } from "./icon-library";
import { WidgetNode } from "./widget";
import { SceneSourceNode } from "./scene";
import { GameCaptureNode } from "./game-capture";
import Bindings from "~/lib/bindings";

type TContent =
  | ImageNode
  | TextNode
  | WebcamNode
  | VideoNode
  | StreamlabelNode
  | WidgetNode
  | SceneSourceNode
  | GameCaptureNode
  | IconLibraryNode;

interface IFilterInfo {
  name: string;
  type: string;
  settings: { [setting: string]: unknown };
}

const displays = ["horizontal", "vertical"] as const;
export type TDisplayType = (typeof displays)[number];

interface ICrop {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

interface IItemSchema {
  id: string;
  name: string;
  sceneNodeType: "item";

  x: number;
  y: number;

  scaleX: number;
  scaleY: number;

  crop?: ICrop;
  rotation?: number;

  content: TContent;

  filters?: IFilterInfo[];

  mixerHidden?: boolean;

  visible?: boolean;
  display?: TDisplayType;
}

export interface IFolderSchema {
  id: string;
  name: string;
  sceneNodeType: "folder";
  childrenIds: string[];
  display?: TDisplayType;
}

export type TSlotSchema = IItemSchema | IFolderSchema;

interface IContext {
  scene: Scene;
  assetsPath: string;
  sceneIdMap: { [sceneId: string]: string };
  streamlabels: { [labelFile: string]: string };
  contents: OverlayContents;
}

export interface ISlotContext {
  assetsPath: string;
  scene: Scene;
  name: string; // Unique name
  originalName: string; // Original name in the overlay, may not be unique
  sceneIdMap: { [sceneId: string]: string };
  item?: SceneItem;
  streamlabels: { [labelFile: string]: string };
  contents: OverlayContents;
  x: number;
  y: number;
}

export class SlotsNode extends ArrayNode<TSlotSchema, IContext> {
  schemaVersion = 1;

  async loadItem(obj: TSlotSchema, context: IContext): Promise<void> {
    if (obj.sceneNodeType === "folder") {
      // TODO: Handle folders
      return;
    }

    if (obj.display != null && obj.display !== 'horizontal') {
      return;
    }

    // Unlike SL Desktop, OBS requires unique names, so we need to rename
    // some of the sources before creating them.
    const uniqueName = await getUniqueSourceName(obj.name);
    const canvas = await Bindings.obs.canvas_get_dimensions();
    const slotContext: ISlotContext = {
      assetsPath: context.assetsPath,
      scene: context.scene,
      name: uniqueName,
      originalName: obj.name,
      sceneIdMap: context.sceneIdMap,
      streamlabels: context.streamlabels,
      contents: context.contents,
      x: obj.x,
      y: obj.y,
    };
    await obj.content.load(slotContext);
    const item = slotContext.item;

    if (!item) {
      console.log(`Node ${obj.name} did not create a scene item!`);
      return;
    }

    await item.setPos(obj.x * canvas.width, obj.y * canvas.height);

    // The webcam node does some advanced cropping and scaling of its own
    if (!(obj.content instanceof WebcamNode)) {
      await item.setScale(
        obj.scaleX * canvas.width,
        obj.scaleY * canvas.height,
      );
      if (obj.crop) await item.setCrop(obj.crop);
    }
  }
}
