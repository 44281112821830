import Bindings from "../bindings";
import { RootNode } from "@/lib/themes/nodes/root";
import { ScenesNode } from "@/lib/themes/nodes/scenes";
import { SlotsNode } from "@/lib/themes/nodes/slots";
import { ImageNode } from "@/lib/themes/nodes/image";
import { TextNode } from "@/lib/themes/nodes/text";
import { WebcamNode } from "@/lib/themes/nodes/webcam";
import { VideoNode } from "@/lib/themes/nodes/video";
import { StreamlabelNode } from "@/lib/themes/nodes/streamlabel";
import { WidgetNode } from "@/lib/themes/nodes/widget";
import { TransitionNode } from "@/lib/themes/nodes/transition";
import { SceneSourceNode } from "@/lib/themes/nodes/scene";
import { GameCaptureNode } from "@/lib/themes/nodes/game-capture";
import { IconLibraryNode } from "@/lib/themes/nodes/icon-library";
import { NodeMapNode } from "@/lib/themes/nodes/node-map";
import { Node } from "@/lib/themes/nodes/node";

export type OverlayContents = { path: string }[];

export async function getConfigFromPaths(paths: OverlayContents) {
  const configPath = getConfigFilePath(paths);

  if (!configPath) {
    throw new Error("Could not find config file path in overlay contents!");
  }

  return {
    config: parse((await Bindings.fs.readFile(configPath)).contents),
    configPath,
    contents: paths,
  };
}

export function getConfigFilePath(contents: OverlayContents) {
  return contents.find((entry) => {
    return entry.path.slice(-11) === "config.json";
  })?.path;
}

const NODE_TYPES: { [nodeName: string]: new () => Node<unknown, unknown> } = {
  RootNode,
  ScenesNode,
  SlotsNode,
  ImageNode,
  TextNode,
  WebcamNode,
  VideoNode,
  StreamlabelNode,
  WidgetNode,
  TransitionNode,
  SceneSourceNode,
  GameCaptureNode,
  IconLibraryNode,
  NodeMapNode,
};

// These nodes will not be created as nodes
const deprecatedNodes = ["FiltersNode"];

export function parse(config: string): RootNode {
  return JSON.parse(config, (key, value) => {
    if (
      typeof value === "object" &&
      value !== null &&
      value.nodeType &&
      !deprecatedNodes.includes(value.nodeType)
    ) {
      const NodeClass = NODE_TYPES[value.nodeType];

      if (NodeClass) {
        const instance = new NodeClass();

        instance.fromJSON(value);
        return instance;
      } else {
        console.warn(`Found unrecognized node in JSON: ${value.nodeType}`);
      }
    }

    return value;
  });
}
