import { Node } from "./node";
import { ScenesNode } from "./scenes";
import { TransitionNode } from "./transition";
import { NodeMapNode } from "./node-map";
import { type OverlayContents } from "../install-theme";

interface ISchema {
  scenes: ScenesNode;
  transition?: TransitionNode;
  nodeMap: NodeMapNode;
}

interface IContext {
  assetsPath: string;
  contents: OverlayContents;
}

export class RootNode extends Node<ISchema, IContext> {
  schemaVersion = 2;

  async load(context: IContext): Promise<void> {
    await this.data?.transition?.load(context);
    await this.data?.scenes.load(context);
  }
}
